<template>
  <div>
    <div class="pointer" @click="() => this.$router.push('/logIn/register')">
      <span> <i class="el-icon-arrow-left"></i> {{ '返回' }} </span>
    </div>
    <div class="title">修改密码</div>
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        hide-required-asterisk
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="请输入手机号"
            v-model.trim="ruleForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <div class="form-div">
            <el-input
              placeholder="请输入验证码"
              v-model.trim="ruleForm.smsCode"
            ></el-input>
            <el-button
              @click="getVerification()"
              plain
              type="primary"
              :disabled="isCell_phone || isBut"
              >{{ butName }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            show-password
            placeholder="请输入新密码"
            type="newPassword"
            v-model.trim="ruleForm.newPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入新密码" prop="verifyPassword">
          <el-input
            show-password
            placeholder="请再次输入新密码"
            type="newPassword"
            v-model.trim="ruleForm.verifyPassword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="sub">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :disabled="isRegister"
              >提交</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getupdatePwd, postverificationCode } from '@/api/login.js';

import '../../assets/css/customElement.less';
export default {
  name: '',
  data() {
    return {
      butName: '获取验证码',
      isBut: false,
      butNum: 60,
      isCell_phone: true,
      isRegister: true,
      ruleForm: {
        phone: this.$route.query.phone || '',
        smsCode: '',
        newPassword: '',
        verifyPassword: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: this.isValidPhoneNumber,
            message: '请输入有效的手机号',
            trigger: 'change',
          },
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        verifyPassword: [
          { required: true, message: '请输入密码', trigger: 'change' },
          {
            validator: this.isverifyPassword,
            message: '密码与第一次输入不一致',
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    // 表单提交
    async submitForm() {
      if (this.ruleForm.newPassword != this.ruleForm.verifyPassword) {
        return this.$message({
          message: '第二次密码与第一次密码不一致,请重新输入',
          type: 'warning',
        });
      }
      const res = await getupdatePwd(this.ruleForm);
      if (res.data.data && res.data.state === '0x0000') {
        this.$message({
          message: '修改成功',
          type: 'success',
        });
        this.$router.push({
          path: '/logIn/register',
          query: {
            tab: 'pass',
          },
        });
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
    // 获取验证码
    async getVerification() {
      const data = {
        phone: this.ruleForm.phone,
        smsType: 2,
      };
      const res = await postverificationCode(data);
      if (res.data.state === '0x0000') {
        this.countDown();
        this.isBut = true;
        this.$message({
          message: '验证码已发送',
          type: 'success',
        });
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
    // 倒计时
    countDown() {
      this.butName = '重新获取' + this.butNum + 's';
      this.butNum--;

      let trueval = setInterval(() => {
        this.butName = '重新获取' + this.butNum + 's';
        this.butNum--;
        if (this.butNum === 0) {
          this.isBut = false;
          this.butName = '获取验证码';
          this.butNum = 60;

          clearInterval(trueval);
        }
      }, 1000);
    },

    isValidPhoneNumber(rule, value, callback) {
      const pattern =
        /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!pattern.test(value)) {
        this.isCell_phone = true;
        callback(true);
      } else {
        this.isCell_phone = false;
      }
    },
    isverifyPassword(rule, value, callback) {
      if (value !== this.ruleForm.newPassword) {
        callback(true);
      }
    },
  },
  watch: {
    ruleForm: {
      handler(newV) {
        const _this = this;
        if (Object.values(newV).every((val) => val !== '')) {
          if (!_this.isCell_phone) _this.isRegister = false;
        } else {
          _this.isRegister = true;
        }
      },
      deep: true,
    },
    'ruleForm.phone': {
      handler(newV) {
        this.isValidPhoneNumber(null, newV);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 68px;
  margin-bottom: 40px;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  color: #6c38e0;
}
.el-form {
  width: 468px;
  .el-form-item {
    .form-div {
      display: flex;
      .el-button {
        margin-left: 10px;
      }
    }
    .sub {
      width: 100%;
      .el-button {
        width: 100%;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
@media screen and (max-width: 1280px) {
  .el-form {
    width: 100%;
    .el-form-item {
      .form-div {
        display: flex;
        .el-button {
          margin-left: 10px;
        }
      }
      .sub {
        width: 100%;
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
